
/**
 * 日期格式化
 * @param date 日期
 * @param fmt 如'yyyy-MM-dd HH:mm:ss'
 * @returns {*}
 */
export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}
export function formatSeconds(value) {
  let result = parseInt(value);
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  res = `${h} : ${m} : ${s}`;
  return res;
}
function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}



/**
 * 获取localStorage
 */
export function setStore (name, content) {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export function getStore (name) {
  if (!name) return;
  var value = window.localStorage.getItem(name);
  if (value !== null) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = typeof value === 'string' ? value : '';
    }
  }
  return value;
}

/**
 * 删除localStorage
 */
export function removeStore (name) {
  if (!name) return;
  window.localStorage.removeItem(name);
}
// 根据生日计算年龄
export function getAge (strBirthday) {
  let returnAge;
  let strBirthdayArr = strBirthday.split('-');
  let birthYear = strBirthdayArr[0];
  let birthMonth = strBirthdayArr[1];
  let birthDay = strBirthdayArr[2];

  let d = new Date();
  var nowYear = d.getFullYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();

  if (nowYear === birthYear) {
    returnAge = 0; // 同年 则为0岁
  } else {
    let ageDiff = nowYear - birthYear; // 年之差
    if (ageDiff > 0) {
      if (nowMonth === birthMonth) {
        let dayDiff = nowDay - birthDay; // 日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth;// 月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; // 返回-1表示出生日期输入错误晚于今天
    }
  }

  return returnAge; // 返回周岁年龄
}
export function textToHtml (str) {
  if (str == null) {
    return '';
  } else if (str.length === 0) {
    return '';
  }
  str = str.replace(/\n/g, '<br/>');
  str = str.replace(new RegExp(/\s/g), '&nbsp;');
  return str;
}