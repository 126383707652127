<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    created() {

  }
}
</script>

<style lang="less">
</style>
