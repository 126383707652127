import axios from '../js/axios'
import qs from 'qs'
import { getParams } from '../js/config'
import apiConfig from '../js/api.config'
axios.defaults.baseURL = apiConfig.baseURL

export function login(params) {
  let data = getParams('login', params);
  return axios.post('/test/login', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}
export function logout(params) {
  let data = getParams('logout', params);
  return axios.post('/test/logout', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}
// 获取课程分类
export function get_course_class(params) {
  let data = getParams('get_course_class', params);
  return axios.post('/test/course_category', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}

// 获取兼职测试
export function get_part_time(params) {
  let data = getParams('get_part_time', params);
  return axios.post('/test/test_list', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}

// 获取兼职详情
export function get_test_topic(params) {
  let data = getParams('get_test_topic', params);
  return axios.post('/test/test_question', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}

// 提交测试
export function submit_test(params) {
  let data = getParams('submit_test', params);
  return axios.post('/test/submit_test', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}
// 获取测试题实操地址
export function get_practice_examples_url(params) {
  let data = getParams('get_practice_examples_url', params);
  return axios.post('/test/get_practice', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}

// 提交实操测试题
export function sub_practice_examples(params) {
  let data = getParams('sub_practice_examples', params);
  return axios.post('/test/submit_practice', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data);
  });
}

// 消息通知
export function getNotice(params) {
  const data = getParams('get_notice', params)
  return axios.post('/test/notice_list', qs.stringify(data)).then((res) => {
    return Promise.resolve(res.data)
  })
};

