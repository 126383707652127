import md5 from 'blueimp-md5'
import { formatDate } from './utils'
import apiConfig from './api.config'
import store from '@/store'

export const BASEURL = apiConfig.baseURL

/**
 * 获取每个接口共同的两个参数
 * @param path
 * @param param
 * @returns {any | ({} & {datetime: *, sign: *} & {})}
 */
export function getParams(path, param) {
  var datakey = 'HPLxWOfp'
  // var datetime = new Date().Format('yyyy-MM-dd HH:mm:ss');
  var datetime = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
  var hash = md5(datakey + '|' + datetime + '|' + path)
  return Object.assign({}, {
    sign: hash,
    datetime: datetime,
  }, param || {})
}
