import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/reset.css'
import { CountDown } from 'vant';
Vue.use(CountDown)
// import 'vant/lib/count-down/style';
Vue.use(ElementUI)
import resetMessage from './assets/js/resetMessage'
Vue.prototype.$message = resetMessage
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
