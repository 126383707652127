import Vue from 'vue'
import VueRouter from 'vue-router'
import top from '../components/top.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: top,
    children: [
      {
        path: '',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/test/:id',
        component: () => import('../views/home/test.vue')
      },
      {
        path: '/actual-operation/:id',
        component: () => import('../views/home/actual-operation.vue')
      },
      {
        path: '/message',
        component: () => import('../views/home/message.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },

]



const router = new VueRouter({
  routes
})

export default router
