<template>
  <div>
    <div class="top">
      <div class="nav">
        <div class="logo-nav">
          <div class="logo_text">业务能力评估系统 </div>
          <!-- <img class="logo" src="../assets/img/logo.png" alt="" /> -->
          <!-- 学员 -->
          <div class="nav-box">
            <router-link to="/" class="active_item" exact>兼职测试</router-link>
          </div>
        </div>

        <div class="right-nav">
          <el-dropdown trigger="click" v-if="token">
            <div class="register">
              <el-avatar :src="avatar" icon="el-icon-user-solid"></el-avatar>
              <div>{{ name }}</div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div @click="chengemsg">
                <el-dropdown-item>消息中心</el-dropdown-item>
              </div>
              <div @click="chengeGologin">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="register" @click="loginpage" v-else>
            <i class="el-icon-user"></i>
            <div>登录</div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getStore, removeStore } from "../assets/js/utils";
import { logout } from "../assets/api/index";
export default {
  data() {
    return {
      avatar: getStore("avatar") ? getStore("avatar") : "",
      name: getStore("name") ? getStore("name") : "",
      token: getStore("token"),
    };
  },
  created(){
  
  },
  methods: {
    chengemsg() {
      if (this.$route.path != "/message") {
        this.$router.push({ path: "/message" });
      }
    },
    async chengeGologin() {
      let res = await logout();
      if(res.code==200){
        removeStore("token");
        removeStore("avatar");
        removeStore("id");
        removeStore("name");
        this.$router.push({ path: "/login" });
      }
      
    },
    loginpage() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.logo_text{
  color: #fff;
  font-size: 18px;
}
.right-nav {
  display: flex;
  align-items: center;
}
.nav-box {
  margin-left: 60px;
  height: 80px;
  line-height: 80px;
  display: flex;
}
.logo-nav {
  display: flex;
  align-items: center;
}
.active_item.router-link-active {
  color: #fff;
  font-weight: 700;
  border-bottom: 3px solid #fbad51;
  background-color: rgba(228, 216, 216, 0.1);
}
.top {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 100;
  width: 100%;
}
.nav {
  width: 1200px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
.nav a {
  color: #fff;
  margin: 0 20px;
  height: 77px;
  display: block;
}
.logo {
  width: 70px;
  height: 70px;
}
.register {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.register div {
  margin-left: 5px;
}
.register:hover {
  color: #fbad51;
}
.register i {
  font-size: 25px;
}
</style>